import React, { useState, useEffect, useRef } from 'react';
import { ActivityIndicator, View, ScrollView, StyleSheet, Text, Image, TouchableOpacity } from 'react-native';
import { useScrollToTop } from '@react-navigation/native';
import axios from 'axios';

import Icon from 'react-native-vector-icons/MaterialIcons';

import Header from '../../Headers/LoggedInHeader';
import UpdatePlan from '../Components/UpdatePlan';

export default function Upgrade({ navigation, host, userInfo, setUserInfo, setAppInfo, appUpdated, setAppUpdated }) {
    const [showLoading, setShowLoading] = useState(false);
    
    const subName = userInfo.sub_name
    const subNameString = 
        subName === 1 ? "Premium" :
        subName === 2 ? "Pro" 
        : "Free Trial";

    const ref = useRef(null);
    useScrollToTop(ref);

    if (showLoading) {
        return (
            <View style={styles.page}>
                <View style={styles.centerScreen}>
                    <ActivityIndicator size="large" color="rgb(54, 117, 212)" />
                </View>
            </View>
        )
    }

    const includedFeatures = [
        "Save 1,000+ hours of development time with Native Notify's push notification infrastructure rather than building your own.",
        'Send and receive unlimited push notifications.',
        'Mass Push Notifications for sending push notifications to all users at once.',
        'Indie Push Notifications for sending push notifications to individuals.',
        'Notification Inbox for Mass Push Notifications.',
        'Indie Notification Inbox for Indie Push Notifications.',
        'Topic Groups to group subscribers by topic and send topic-based notifications.',
        'Follow Push Notifications to allow users to follow each other and send follower-specific notifications.',
        'Expedited customer support.',
    ];

    return (
        <View style={styles.page}>
            <Header
                navigation={navigation}
                setAppInfo={setAppInfo}
                userInfo={userInfo}
            />
            <UpdatePlan 
                host={host} 
                hideFreePlan={true} 
                subNameString={subNameString} 
                setAppUpdated={setAppUpdated}
            />
        </View>
    )
}


const styles = StyleSheet.create({
    page: {
        flex: 1,
        backgroundColor: "white",
        alignItems: 'center',
    },
    scrollViewContPro: {
        width: '100%',
        backgroundColor: "#eceef2",
    },
    scrollView: {
        width: '100%',
        alignItems: 'center',
        paddingBottom: 100,
        paddingHorizontal: '5%',
    },
    container: {
        width: '100%',
        alignItems: 'center',
    },
    centerScreen: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },

    goProCont: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 15,
        paddingTop: 30,
        paddingBottom: 30,
        textAlign: 'center',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 30,
        width: '100%',
        maxWidth: 1200,
        shadowColor: '#6b6b6b',
        shadowOpacity: 0.3,
        shadowRadius: 3,
        elevation: 3,
    },

    h1: {
        fontFamily: 'Arial',
        fontWeight: '700',
        fontSize: 24,
        marginBottom: 10,
        color: '#000',
    },
    subHeader: {
        fontFamily: 'Arial',
        fontWeight: '500',
        color: "#6b6b6b",
        fontSize: 18,
        marginBottom: 10,
        textAlign: 'center',
        maxWidth: 800
    },
    pSmall: {
        fontSize: 12,
        fontFamily: 'Arial',
        fontWeight: '400',
        color: "#6b6b6b",
    },

    logo: {
        width: 60,
        height: 60
    },
    goPremiumButton: {
        marginTop: 20,
        marginBottom: 10,
        backgroundColor: '#2690cb',
        borderRadius: 6,
        paddingVertical: 10,
        paddingHorizontal: 20,
        shadowColor: '#26272b',
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 2,
    },

    pricingContainer: {
        width: '100%',
        maxWidth: 1200,
        marginTop: 30,
        marginBottom: 30,
        alignItems: 'center',
    },
    pricingTable: {
        width: '100%',
        borderWidth: 1,
        borderColor: '#e0e0e0',
        borderRadius: 5,
        overflow: 'hidden',
        marginBottom: 30,
        backgroundColor: 'white',
    },
    pricingRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
    },
    pricingCell: {
        flex: 1,
        padding: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },
    pricingHeader: {
        backgroundColor: '#f5f5f5',
        fontWeight: 'bold',
        fontSize: 18,
    },
    includedFeatures: {
        width: '100%',
        backgroundColor: "white",
        borderWidth: 1,
        borderColor: '#e0e0e0',
        borderRadius: 5,
    },
    featureRow: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
    },
    checkIcon: {
        marginRight: 15,
    },
    featureItem: {
        flex: 1,
        fontSize: 18,
        fontFamily: 'Arial',
        lineHeight: 26,
        color: '#333',
    },

    maxWidth: {
        width: '100%',
        maxWidth: 1200,
    },
    faqContainer: {
        width: '100%',
        maxWidth: 800,
        marginTop: 20,
    },
    faqItem: {
        marginBottom: 20,
        paddingBottom: 20,
        borderBottomWidth: 1,
        borderBottomColor: '#e0e0e0',
    },
    faqQuestion: {
        fontSize: 20,
        fontFamily: 'Arial',
        fontWeight: 'bold',
        marginBottom: 10,
        color: '#2690cb',
    },
    faqAnswer: {
        fontSize: 18,
        fontFamily: 'Arial',
        marginBottom: 5,
        lineHeight: 26,
        color: '#333',
    },
});
